import { login, register } from "./auth";
import {
  createGame,
  updateMove,
  markAsDraw,
  resetGame,
  deleteGame,
  getPrevGamesList,
  getGameDetails
} from "./games";

// creating an api object to easily access all the api functions
const api = {
  auth: {
    login,
    register,
  },
  games: {
    createGame,
    updateMove,
    markAsDraw,
    resetGame,
    deleteGame,
    getPrevGamesList,
    getGameDetails,
  },
};

export default api;
