import { BLACK, WHITE } from "./constants";
import api from "./api";


/**
 * Record the game move in the game history object
 * @param gameHistoryEntry Current game history entry
 * @param player  Current player (BLACK or WHITE)
 * @param row move row
 * @param column move column
 */
export async function recordMove(
  player: typeof BLACK | typeof WHITE,
  row: number,
  column: number,
  gameId: string
) {
  const response = await api.games.updateMove(gameId, { player, move: { r: row, c: column } });
  return response
}

/**
 * Mark the current game as finished with "Draw" status
 * @param gameId 
 * @returns 
 */
export async function markGameAsDraw(gameId: string) {
  const response = await api.games.markAsDraw(gameId);
  return response
}

/**
 * Resets the current game
 * @param gameId 
 * @returns 
 */
export async function resetTheGame(gameId: string) {
  const response = await api.games.resetGame(gameId);
  return response
}

/**
 * Deletes the game record with the given id
 * @param gameId 
 * @returns 
 */
export async function deleteTheGame(gameId: string) {
  const response = await api.games.deleteGame(gameId);
  return response
}
