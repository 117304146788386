import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import gumkuImageUrl from "../assets/images/gumoku-transparent.png";
import AppButton from "../components/AppButton";
import { useGameStore, useAuth } from "../stores";
import api from "../api";

export default function HomePage() {
  // getting board sizes from 5x5 to 20x20
  const boardSizeOptions = new Array(16).fill(0).map((_, index) => ({
    value: index + 5,
    label: `${index + 5}x${index + 5}`,
  }));

  // get the setGameBoardSize function from the game store
  const setGameBoardSize = useGameStore((state) => state.setGameBoardSize);
  const setGameId = useGameStore((state) => state.setGameId);

  const [boardSize, setBoardSize] = useState(5);

  // creating navigate function from react-router-dom
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  async function handleStartGame(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const createdGame = await api.games.createGame(boardSize);
    setLoading(false);
    if (!createdGame) {
      alert("Error creating game");
      return;
    }
    setGameBoardSize(createdGame.data.boardSize);
    setGameId(createdGame.data._id);
    navigate("/game");
  }

  const { user } = useAuth();

  return (
    <div className="home-page">
      <div className="game-intro">
        <h1> {user ? `Hi ${user.username},` : ""} Let's play Gomoku !</h1>
        <img src={gumkuImageUrl} alt="Gomoku game board" />
      </div>
      <form className="game-start" onSubmit={handleStartGame}>
        <div className="form-item form-item--horizontal">
          <label htmlFor="board-size">Board size</label>
          <select
            className="app-input"
            id="board-size"
            name="board-size"
            onChange={(e) => {
              setBoardSize(Number(e.target.value));
            }}
            value={boardSize}
          >
            {boardSizeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <AppButton loading={loading}>Start !</AppButton>
      </form>
    </div>
  );
}
