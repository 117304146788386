import {AxiosError} from 'axios'
import {instance} from "./instance"
import { LoginDTO, SignupDTO, SignupResponse, User } from "../types";


/**
 * Register a new user
 * @param user 
 * @returns 
 */
export async function register(user: SignupDTO) {
  try {
    const response = await instance.post<SignupResponse>("/auth/register", user);
    console.log(response)
    return response.data
  } catch (error) {
    const axiosError = error as AxiosError
    throw axiosError.response?.data
  }
}


/**
 * Login a user
 * @param user 
 * @returns 
 */
export async function login(user: LoginDTO) {
  try {
    const response = await instance.post<User>("/auth/login", user);
    console.log(response)
    return response.data
  } catch (error) {
    console.log(error)
  }
}
