import { create } from "zustand";
import { BLACK, WHITE } from '../constants';

/**
 * The GameState interface defines the shape of the state object of the game store
 */
interface GameState {
  // The size of the game board
    gameBoardSize: number
    // A function to set the game board size
    setGameBoardSize: (size: number) => void
    // The current player
    currentPlayer: typeof BLACK | typeof WHITE
    // A function to set the current player
    setCurrentPlayer: (player: typeof BLACK | typeof WHITE) => void
    // the current game's id
    gameId: string
    // a function to set the current game's id
    setGameId: (id: string) => void
}

export const useGameStore = create<GameState>()(function (set) {
  return {
    gameBoardSize: 5,
    setGameBoardSize: (size: number) => set({ gameBoardSize: size }),
    currentPlayer: 'black',
    setCurrentPlayer: (player: 'black' | 'white') => set({ currentPlayer: player }),
    gameId: '',
    setGameId: (id: string) => set({ gameId: id })
  };
});
