import {
  CreateGameResponse,
  GameDetailsResponse,
  GameListResponse,
} from "../types";
import { authenticatedInstance } from "./instance";

/**
 * Get the list of previous games for the logged in user
 * @returns 
 */
export async function getPrevGamesList() {
  try {
    const response = await authenticatedInstance().get<GameListResponse>(
      `/games`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

/**
 * Get the details of the game with the given id
 * @param gameId 
 * @returns 
 */
export async function getGameDetails(gameId: string) {
  try {
    const response = await authenticatedInstance().get<GameDetailsResponse>(
      `/games/${gameId}`
    );
    console.log(response.data.data);
    return response.data.data;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

/**
 * Create a new game with the given board size
 * @param gameboardSize 
 * @returns 
 */
export async function createGame(gameboardSize: number) {
  try {
    const response = await authenticatedInstance().post<CreateGameResponse>(
      `/games?size=${gameboardSize}`
    );
    if (response.status === 201) {
      return response.data;
    }
    throw new Error(response.data.message);
  } catch (error) {
    console.log(error);
  }
}

/**
 * Delete the game with the given id
 * @param gameId 
 * @returns 
 */
export async function deleteGame(gameId: string) {
  try {
    const response = await authenticatedInstance().delete<{
      message: string;
      data: boolean;
    }>(`/games/${gameId}`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Update the game with the given id with the given data
 * @param gameId 
 * @param data 
 * @returns 
 */
export async function updateMove(gameId: string, data: object) {
  try {
    const response = await authenticatedInstance().put<{
      message: string;
      data: boolean;
    }>(`/games/${gameId}`, data);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Mark the game with the given id as draw
 * @param gameId 
 * @returns 
 */
export async function markAsDraw(gameId: string) {
  try {
    const response = await authenticatedInstance().patch<{
      message: string;
      data: boolean;
    }>(`/games/${gameId}/draw`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Resets the current playing game
 * @param gameId 
 * @returns 
 */
export async function resetGame(gameId: string) {
  try {
    const response = await authenticatedInstance().patch<{
      message: string;
      data: boolean;
    }>(`/games/${gameId}/reset`);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
}
