import React from "react";
import ReactDOM from "react-dom/client";
import { AppRoutes } from "./router";
import { AuthProvider } from "./stores";
import { BrowserRouter } from "react-router-dom";

// Import the css file
import "./assets/css/main.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    {/* Wrapping in auth provider */}
    <AuthProvider>
      {/* Rendering the routes using BrowserRouter */}
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
