import axios, {AxiosError} from "axios";
import { User } from "../types";



/**
 * Get a properly configured axios instance for making authenticated requests
 * @returns 
 */
export function authenticatedInstance() {
    const savedUser = localStorage.getItem("user");
    if (!savedUser) {
        throw new AxiosError("User not logged in", "401");
    }
    const user = JSON.parse(savedUser) as User;
    const token = user ? user.token : null;
    if (!token) {
        throw new AxiosError("User not logged in", "401");
    }
    return axios.create({
        baseURL: "https://web-game.onrender.com/api",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    });
}


/**
 * A properly configured axios instance for making unauthenticated requests
 */
export const instance = axios.create({
    baseURL: "https://web-game.onrender.com/api",
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});;
