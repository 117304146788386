import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../stores";
import AppButton from "./AppButton";

export default function AppHeader() {
  const {user, logout} = useAuth();

  const navigate = useNavigate();
  function logoutUser() {
    console.log("logout");
    logout();
    navigate("/");
  }

  return (
    <header className="app-header">
      <Link to="/" className="app-header__brand">
        Gomoku
      </Link>
      <nav className="app-header__nav">
        <ul className="app-header__nav-list">
          {!user ? (
            <li>
              <AppButton isLink={true} to="/login" mode="text">
                Login
              </AppButton>
            </li>
          ) : (
            <>
              <li>
                <AppButton to="/games" isLink={true} mode="text">
                  Previous Games
                </AppButton>
              </li>
              <li>
                <AppButton mode="text" onClick={logoutUser}>Logout</AppButton>
              </li>
            </>
          )}
        </ul>
      </nav>
    </header>
  );
}
