import { Outlet } from "react-router-dom";
import AppHeader from "../components/AppHeader";

export default function DefaultLayout() {

  return (
    <div>
      <AppHeader />
      <main>
        <Outlet />
      </main>
    </div>
  );
}
