import { deleteTheGame } from "../game-logic";
import { Game } from "../types";
import { formatDateToDDMMYYYY } from "../utils/formatDate";
import AppButton from "./AppButton";

export interface GameHistoryEntryProps {
  _id: string;
  date: Game["date"];
  winner: Game["winner"];
  gameIndex: number;
  onDelete?: () => void;
}

export default function GameHistoryEntryElement({
  _id,
  date,
  winner,
  gameIndex,
  onDelete,
}: GameHistoryEntryProps) {
  async function deleteSavedGame() {
    const answer = window.confirm(
      "Are you sure you want to delete this record from saved games?"
    );
    if (answer) {
      await deleteTheGame(_id);
      if (onDelete) {
        onDelete();
      }
    }
  }

  return (
    <li className="game-history-entry">
      <div className="game-history-entry__name">
        <p>Game #{gameIndex + 1}</p>
        <p>@ {formatDateToDDMMYYYY(date)}</p>
      </div>
      <p>
        {" "}
        {winner === "Draw"
          ? `Game is a draw`
          : `Winner: ${winner === "black" ? "Black" : "White"}`}{" "}
      </p>
      <AppButton mode="text" isLink={true} to={`/game-log/${_id}`}>
        View game log
      </AppButton>
      <AppButton mode="text" onClick={deleteSavedGame}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M7 21q-.825 0-1.413-.588T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.588 1.413T17 21H7ZM17 6H7v13h10V6ZM9 17h2V8H9v9Zm4 0h2V8h-2v9ZM7 6v13V6Z"
          ></path>
        </svg>
      </AppButton>
    </li>
  );
}
