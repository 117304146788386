import { PropsWithChildren, HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export interface AppButtonProps extends HTMLAttributes<HTMLButtonElement> {
  isLink?: boolean;
  to?: string;
  mode?: "solid" | "text";
  loading?: boolean;
}

const AppButton = ({
  children,
  isLink,
  to,
  mode = "solid",
  loading = false,
  onClick,
  ...otherProps
}: PropsWithChildren<AppButtonProps>) => {
  return (
    <>
      {isLink && to ? (
        <Link
          to={to}
          className={classNames({
            "app-button": true,
            "app-button--text": mode === "text",
          })}
        >
          {children}
        </Link>
      ) : (
        <button
          className={classNames({
            "app-button": true,
            "app-button--text": mode === "text",
          })}
          onClick={onClick}
          {...otherProps}
        >
          {loading ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
              >
                <animateTransform
                  attributeName="transform"
                  dur="0.75s"
                  repeatCount="indefinite"
                  type="rotate"
                  values="0 12 12;360 12 12"
                ></animateTransform>
              </path>
            </svg>
          ) : null}
          {children}
        </button>
      )}
    </>
  );
};

export default AppButton;
