import { GameList } from "../types";
import GameHistoryEntryElement from "../components/GameHistoryEntryElement";
import { useEffect, useState } from "react";
import api from "../api";
import { GamesLoadingIndicator } from "../components/GamesLoadingIndicator";

export default function GamesPage() {
  // state to store the game history entries
  const [gameList, setGameList] = useState<GameList>([]);
  const [loadingList, setLoadingList] = useState(true);

  async function loadPreviousGames() {
    setLoadingList(true);
    const gameList = await api.games.getPrevGamesList();
    setGameList(gameList);
    setLoadingList(false);
  }

  useEffect(() => {
    loadPreviousGames();
  }, []);

  return (
    <div className="games-page">
      {loadingList && <GamesLoadingIndicator />}
      <h1>
        {!loadingList && gameList.length === 0
          ? "Haven't played any games yet"
          : ""}
      </h1>
      <ul className="game-history-entries">
        {!loadingList &&
          gameList.map((gameListItem, index) => {
            return (
              <GameHistoryEntryElement
                gameIndex={index}
                {...gameListItem}
                key={gameListItem._id}
                onDelete={async () => {
                  await loadPreviousGames();
                }}
              />
            );
          })}
      </ul>
    </div>
  );
}
