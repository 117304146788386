import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./stores";
import DefaultLayout from "./layouts/DefaultLayout";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import GamesPage from "./pages/GamesPage";
import GamePage from "./pages/GamePage";
import GameLogPage from "./pages/GameLogPage";

export const ProtectedRoute = ({ children }: { [key: string]: any }) => {
  // checking if use is logged in using zustand state
  const {user} = useAuth();
  if (!user) {
    console.log("user not found");
    // got to login page if user is not authenticated
    return <Navigate to="/login" replace />;
  }
  // otherwise render the children, in this case the page we want to protect
  return children;
};

export function AppRoutes() {

  return (
    <Routes>
      {/* Setting a layout to have a common header easily */}
      <Route path="/" element={<DefaultLayout />}>
        <Route path="" element={<HomePage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        {/* Protected routes with the ProtectedRoute component */}
        <Route
          path="games"
          element={
            <ProtectedRoute>
              <GamesPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="game"
          element={
            <ProtectedRoute>
              <GamePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="game-log/:id"
          element={
            <ProtectedRoute>
              <GameLogPage />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* Adding a fallback page for not found pages  */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}
