import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppButton from "../components/AppButton";
import api from "../api";
import { useAuth } from "../stores";

export default function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // getting the setUser function from the auth store
  const { login: setUser } = useAuth();

  // creating navigate function from react-router-dom
  const navigate = useNavigate();

  async function handleLoginForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    console.log(username, password);
    try {
      const user = await api.auth.login({ username, password });
      if (!user) {
        throw new Error("Invalid username or password");
      }
      setLoginError(null);
      setUser(user);
      navigate("/");
    } catch (error) {
      setLoginError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="login-page">
        <form className="login-form" onSubmit={handleLoginForm}>
          <h1>Welcome back !</h1>
          <div className="form-item">
            <label htmlFor="username">Username</label>
            <input
              className="app-input"
              id="username"
              name="username"
              onChange={(event) => setUsername(event.target.value)}
              value={username}
            />
          </div>
          <div className="form-item">
            <label htmlFor="password">Password</label>
            <input
              className="app-input"
              id="password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              value={password}
            />
          </div>
          {loginError && <p className="login-form__error">{loginError}</p>}
          <AppButton loading={loading}>Sign in</AppButton>
          <p>
            Don't have an account ? <Link to="/register" style={{color: 'blue'}}>Sign up</Link>
          </p>
        </form>
      </div>
    </>
  );
}
