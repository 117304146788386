import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../components/AppButton";
import api from "../api";
// import { useAuth } from "../stores";

export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registerError, setRegisterError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // creating navigate function from react-router-dom
  const navigate = useNavigate();

  async function handleRegisterForm(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    console.log(username, password);
    try {
      const user = await api.auth.register({ username, password, confirmPassword });
      if (!user) {
        throw new Error("Invalid username or password");
      }
      setRegisterError(null);
      navigate("/login");
    } catch (error) {
      console.log(error);
      setRegisterError((error as Error).message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="login-page">
        <form className="login-form" onSubmit={handleRegisterForm}>
          <h1>Welcome back !</h1>
          <div className="form-item">
            <label htmlFor="username">Username</label>
            <input
              className="app-input"
              id="username"
              name="username"
              onChange={(event) => setUsername(event.target.value)}
              value={username}
            />
          </div>
          <div className="form-item">
            <label htmlFor="password">Password</label>
            <input
              className="app-input"
              id="password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              type="password"
              value={password}
            />
          </div>
          <div className="form-item">
            <label htmlFor="confirm-password">Confirm password</label>
            <input
              className="app-input"
              id="confirm-password"
              name="confirm-password"
              onChange={(event) => setConfirmPassword(event.target.value)}
              type="password"
              value={confirmPassword}
            />
          </div>
          {registerError && (
            <p className="login-form__error">{registerError}</p>
          )}
          <AppButton loading={loading}>Register</AppButton>
        </form>
      </div>
    </>
  );
}
