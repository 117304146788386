import { useNavigate } from "react-router-dom";
import { useGameStore } from "../stores";
import { useState } from "react";
import { BLACK, WHITE } from "../constants";
import {
  deleteTheGame,
  markGameAsDraw,
  recordMove,
  resetTheGame,
} from "../game-logic";
import AppButton from "../components/AppButton";

let totalGameMoves = 0;

export default function GamePage() {
  // getting the game board size and current player from the store
  const gameId = useGameStore((state) => state.gameId);
  const gameBoardSize = useGameStore((state) => state.gameBoardSize);
  const currentPlayer = useGameStore((state) => state.currentPlayer);
  const setCurrentPlayer = useGameStore((state) => state.setCurrentPlayer);

  const navigate = useNavigate();

  let row = 0;
  let column = -1;

  const [gameStatus, setGameStatus] = useState<"won" | "draw" | "playing">(
    "playing"
  );
  const [gameWinner, setGameWinner] = useState<
    typeof BLACK | typeof WHITE | ""
  >("");

  const [isChecking, setIsChecking] = useState(false);
  async function runGame(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: number,
    column: number
  ) {
    if (gameStatus !== "playing" || isChecking) return;
    setIsChecking(true);
    const cell = e.target as HTMLDivElement;
    // if clicked on a cell that is not empty, return
    if (
      cell.style.backgroundColor === WHITE ||
      cell.style.backgroundColor === BLACK
    )
      return;

    // set the color of the cell to the current player
    cell.style.backgroundColor = currentPlayer;
    cell.style.cursor = "not-allowed";

    // record the move in the game history object
    const isWinner = await recordMove(currentPlayer, row, column, gameId);
    setIsChecking(false);
    if (isWinner) {
      // if there is a winner, set the game status to won
      setGameStatus("won");

      // set the game winner
      setGameWinner(currentPlayer);
    } else if (isWinner === undefined) {
      alert("Something went wrong!");
    }

    totalGameMoves++;
    console.log(totalGameMoves);
    if (totalGameMoves === gameBoardSize * gameBoardSize) {
      setGameStatus("draw");
      await markGameAsDraw(gameId);
    } else if (isWinner === false) {
      setCurrentPlayer(currentPlayer === "black" ? "white" : "black");
    }
  }

  const [isResetting, setIsResetting] = useState(false);
  async function resetGame() {
    if (isResetting) return;
    setIsResetting(true);
    await resetTheGame(gameId);
    // clear the game board
    const cells = document.querySelectorAll(".game-board__cell");
    cells.forEach((cell) => {
      if (cell instanceof HTMLDivElement) {
        cell.style.backgroundColor = "";
        cell.style.cursor = "pointer";
      }
    });
    setGameStatus("playing");
    setCurrentPlayer("black");
    totalGameMoves = 0;
    setIsResetting(false);
  }

  async function leaveGame() {
    console.log(gameStatus);
    if (gameStatus === "won" || gameStatus === "draw") {
      // if won or draw, navigate to the games page
      navigate("/games");
      return;
    }

    // if playing, ask the user if they want to leave the game
    const answer = window.confirm(
      "Are you sure you want to leave the game?All progress will be lost!"
    );
    if (answer) {
      // if yes, delete the game from the database and navigate to the games page
      await deleteTheGame(gameId);
      navigate("/");
    }
  }

  return (
    <div className="game-page">
      {" "}
      {isChecking ? (
        <h1>Please wait</h1>
      ) : gameStatus === "playing" ? (
        <h1>
          Current Player{" "}
          <div
            style={{
              backgroundColor: currentPlayer,
              width: "1.5rem",
              height: "1.5rem",
              borderRadius: "50%",
              border: "1px solid black",
            }}
          ></div>{" "}
        </h1>
      ) : gameStatus === "won" ? (
        <h1>Player {gameWinner === "black" ? "Black" : "White"} won!</h1>
      ) : (
        <h1>Draw!</h1>
      )}
      <div
        className="game-board"
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${gameBoardSize}, 1fr)`,
          gridTemplateRows: `repeat(${gameBoardSize}, 1fr)`,
        }}
      >
        {new Array(gameBoardSize * gameBoardSize).fill(0).map(() => {
          column++;
          if (column > gameBoardSize - 1) {
            row++;
            column = 0;
          }
          const currentCol = column;
          const currentRow = row;
          return (
            <div
              className="game-board__cell"
              key={`r=${currentRow}<->c=${currentCol}`}
              onClick={(e) => {
                runGame(e, currentRow, currentCol);
              }}
              id={`box-${currentRow}-${currentCol}`}
              style={{
                cursor: gameStatus === "playing" ? "pointer" : "not-allowed",
              }}
            ></div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "2rem",
          marginBlock: "2rem",
        }}
      >
        <AppButton mode="text" onClick={leaveGame}>
          Leave game
        </AppButton>
        <AppButton mode="solid" onClick={resetGame} loading={isResetting}>
          {isResetting ? "Resetting" : "Reset game"}
        </AppButton>
      </div>
    </div>
  );
}
