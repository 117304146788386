
/**
 * Format a date as DD/MM/YYYY
 * @param date the date to be formatted
 * @returns 
 */
export function formatDateToDDMMYYYY(date :string | Date) {
    date = new Date(date)  
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }