import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useCallback } from "react";
import { BLACK, WHITE } from "../constants";
import { Game } from "../types";
import api from "../api";
import AppButton from "../components/AppButton";
import { GamesLoadingIndicator } from "../components/GamesLoadingIndicator";

export default function GameLogPage() {
  // get the navigation hook from react router dom
  const navigate = useNavigate();

  // getting the route params to extract the game id
  const params = useParams<{ id: string }>();

  const [game, setGame] = useState<Game | undefined>(undefined);
  const [isLoadingGame, setIsLoadingGame] = useState(true);

  const loadGame = useCallback(async () => {
    setIsLoadingGame(true);
    const response = await api.games.getGameDetails(params.id as string);
    if (response) {
      setGame(response);
      console.log(response.moves);
    }
    setIsLoadingGame(false);
  }, [params.id]);

  useEffect(() => {
    loadGame();
  }, [loadGame]);

  let row = 0;
  let column = -1;

  const [inReplay, setInReplay] = useState(false);
  const [inCommentaryMode, setInCommentaryMode] = useState(true);
  const [commentaryList, setCommentaryList] = useState<string[]>([]);

  async function startReplay() {
    // if already in replay mode, return
    if (inReplay) return;

    // set replay mode to true
    setInReplay(true);

    // get all the cells
    const gameBoardCells = document.querySelectorAll(
      ".game-board__cell"
    ) as NodeListOf<HTMLDivElement>;

    // clear the board
    gameBoardCells.forEach((cell) => {
      cell.style.backgroundColor = "";
    });

    // clear the commentary
    setCommentaryList([]);

    if (!game) return;

    // get all the moves of black player
    const blackMoves = game.moves.black.map((move) => {
      return { ...move, color: BLACK };
    });

    // get all the moves of white player
    const whiteMoves = game.moves.white.map((move) => {
      return { ...move, color: WHITE };
    });

    const moves = [];
    const maxMoves = Math.max(blackMoves.length, whiteMoves.length);

    for (let i = 0; i < maxMoves; i++) {
      if (blackMoves[i]) moves.push(blackMoves[i]);
      if (whiteMoves[i]) moves.push(whiteMoves[i]);
    }

    for (const move of moves) {
      // get index of current move
      const index = moves.indexOf(move);
      // change color of current move
      await changeColor(move, index);
    }

    setInReplay(false);
  }

  function changeColor(
    move: { r: number; c: number; color: string },
    index: number
  ) {
    // create a promise that resolves after 500ms to create a delay
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        // get the cell to change color
        const cell = document.getElementById(
          `box-${move.r}-${move.c}`
        ) as HTMLDivElement;

        const isActuallyFilled = move.color !== "";
        // change the color of the cell
        if (isActuallyFilled) cell.style.backgroundColor = move.color;
        // if move is black or white and commentary mode is on, change color and add commentary
        if (isActuallyFilled && inCommentaryMode) {
          // add commentary to the commentary list
          setCommentaryList((prev) => [
            `Move ${index + 1} :${
              move.color === BLACK ? "Black" : "White"
            } to row ${move.r + 1}, column ${move.c + 1}`,
            ...prev,
          ]);
          resolve();
        }
        resolve();
      }, 500);
    });
  }

  return (
    <div className="game-log-page">
      {!game ? (
        isLoadingGame ? (
          <GamesLoadingIndicator />
        ) : (
          <h1>Game with given id doesn't exist</h1>
        )
      ) : (
        <>
          <AppButton
            mode="text"
            onClick={() => {
              navigate(-1);
            }}
            style={{
              position: "absolute",
              top: "1rem",
              left: "2rem",
            }}
          >
            {" "}
            Back{" "}
          </AppButton>
          <h1>
            {game.winner === BLACK
              ? "Winner was Black"
              : game.winner === "Draw"
              ? "Game was a draw"
              : "Winner was White"}{" "}
            !
          </h1>
          <div
            className="game-board"
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${game.boardSize}, 1fr)`,
              gridTemplateRows: `repeat(${game.boardSize}, 1fr)`,
            }}
          >
            {new Array(game.boardSize * game.boardSize).fill(0).map(() => {
              if (!game) {
                return (
                  <div className="game-board__cell game-board__cell--completed"></div>
                );
              }
              column++;
              if (column > game.boardSize - 1) {
                row++;
                column = 0;
              }
              const currentCol = column;
              const currentRow = row;

              const bgColor = game.moves.black.find(
                (m) => m.r === currentRow && m.c === currentCol
              )
                ? BLACK
                : game.moves.white.find(
                    (m) => m.r === currentRow && m.c === currentCol
                  )
                ? WHITE
                : "";

              return (
                <div
                  className="game-board__cell game-board__cell--completed"
                  key={`r=${currentRow}<->c=${currentCol}`}
                  id={`box-${currentRow}-${currentCol}`}
                  style={{
                    backgroundColor: bgColor,
                  }}
                ></div>
              );
            })}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
              marginBlock: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <input
                type="checkbox"
                name="live-commentary"
                id="live-commentary"
                onChange={(e) => {
                  setInCommentaryMode(e.target.checked);
                }}
                checked={inCommentaryMode}
              />
              <label htmlFor="live-commentary">
                Turn on live commentary for replay
              </label>
            </div>
            <AppButton mode="solid" onClick={startReplay}>
              Watch replay
            </AppButton>
          </div>
          {inCommentaryMode ? (
            <div className="commentary-container">
              <h2>Commentary</h2>
              <ul className="live-commentary">
                {commentaryList.map((comment) => (
                  <li key={comment}>{comment}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
