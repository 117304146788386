import AppButton from "../components/AppButton";

export default function ErrorPage() {
  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>You have entered  a mysterious place.</p>
        <AppButton to="/" isLink={true} mode="text" >Go back to safety</AppButton>
    </div>
  );
}